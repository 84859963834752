root {
  display: "flex";
  flex-wrap: "wrap";
}

#portfolio {
  background: #ebeeee;
  padding-top: 90px;
  padding-bottom: 60px;
}
#portfolio h1 {
  font: 15px/24px "opensans-semibold", sans-serif;
  text-transform: uppercase;
  letter-spacing: 1px;
  text-align: center;
  margin-bottom: 48px;
  color: #95a3a3;
}

#portfolio h3 {
  margin: 25px 0;
  text-align: center;
}

/* Portfolio Content */
.portfolio-wrapper .columns {
  margin-bottom: 36px;
}
.portfolio-item .item-wrap {
  background: #fff;
  position: relative;

  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
.portfolio-item .item-wrap a {
  display: block;
  cursor: pointer;
}

/* overlay */
.portfolio-item .item-wrap .overlay {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;

  opacity: 0;
  -moz-opacity: 0;
  filter: alpha(opacity=0);

  -webkit-transition: opacity 0.3s ease-in-out;
  -moz-transition: opacity 0.3s ease-in-out;
  -o-transition: opacity 0.3s ease-in-out;
  transition: opacity 0.3s ease-in-out;

  background: url(./images/overlay-bg.png) repeat;
}
.portfolio-item .item-wrap .link-icon {
  display: block;
  color: #fff;
  height: 30px;
  width: 30px;
  font-size: 18px;
  line-height: 30px;
  text-align: center;

  opacity: 0;
  -moz-opacity: 0;
  filter: alpha(opacity=0);

  -webkit-transition: opacity 0.3s ease-in-out;
  -moz-transition: opacity 0.3s ease-in-out;
  -o-transition: opacity 0.3s ease-in-out;
  transition: opacity 0.3s ease-in-out;

  position: absolute;
  top: 50%;
  left: 50%;
  margin-left: -15px;
  margin-top: -15px;
}
.portfolio-item .item-wrap img {
  vertical-align: bottom;
}
.portfolio-item .portfolio-item-meta {
  padding: 18px;
}
.portfolio-item .portfolio-item-meta h5 {
  font: 14px/21px "opensans-bold", sans-serif;
  color: #fff;
}
.portfolio-item .portfolio-item-meta p {
  font: 12px/18px "opensans-light", sans-serif;
  color: #c6c7c7;
  margin-bottom: 0;
}

/* on hover */
.portfolio-item:hover .overlay {
  opacity: 1;
  -moz-opacity: 1;
  filter: alpha(opacity=100);
}
.portfolio-item:hover .link-icon {
  opacity: 1;
  -moz-opacity: 1;
  filter: alpha(opacity=100);
}

/* popup modal */
.popup-modal {
  max-width: 800px;
  background: #fff;
  position: relative;
  margin: 0 auto;
}
.popup-modal .description-box {
  padding: 12px 36px 18px 36px;
}
.popup-modal .description-box h4 {
  font: 20px/30px "opensans-bold", sans-serif;
  margin-bottom: 12px;
  color: #111;
}
.popup-modal .description-box p {
  font: 14px/24px "opensans-regular", sans-serif;
  color: #a1a1a1;
  margin-bottom: 12px;
}
.popup-modal .description-box iframe {
  width: 100%;
  height: 400px;
}
.popup-modal .description-box .categories {
  font: 11px/21px "opensans-light", sans-serif;
  color: #a1a1a1;
  text-transform: uppercase;
  letter-spacing: 2px;
  display: block;
  text-align: left;
}
.popup-modal .description-box .categories i {
  margin-right: 8px;
}
.popup-modal .link-box {
  padding: 18px 36px;
  background: #111;
  text-align: left;
}
.popup-modal .link-box a {
  color: #fff;
  font: 11px/21px "opensans-bold", sans-serif;
  text-transform: uppercase;
  letter-spacing: 3px;
  cursor: pointer;
}
.popup-modal a:hover {
  color: #00cccc;
}
.popup-modal a.popup-modal-dismiss {
  margin-left: 24px;
}

.center-cropped {
  -o-object-fit: cover;
  object-fit: cover;
  /* Do not scale the image */
  -o-object-position: center;
  object-position: center;
  /* Center the image within the element */
}

/* fadein/fadeout effect for modal popup
  /* ------------------------------------------------------------------ */

/* content at start */
.mfp-fade.mfp-wrap .mfp-content .popup-modal {
  opacity: 0;
  -webkit-transition: all 200ms ease-in-out;
  -moz-transition: all 200ms ease-in-out;
  -o-transition: all 200ms ease-in-out;
  -ms-transition: all 200ms ease-in-out;
  transition: all 200ms ease-in-out;
}
/* content fadein */
.mfp-fade.mfp-wrap.mfp-ready .mfp-content .popup-modal {
  opacity: 1;
}
/* content fadeout */
.mfp-fade.mfp-wrap.mfp-removing .mfp-content .popup-modal {
  opacity: 0;
}
