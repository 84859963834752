header {
  position: relative;
  height: 800px;
  min-height: 500px;
  width: 100%;
  background: #161415 url(./images/header-background.png) no-repeat top center;
  background-size: cover !important;
  -webkit-background-size: cover !important;
  text-align: center;
  overflow: hidden;
}

/* vertically center banner section */
header:before {
  content: "";
  display: inline-block;
  vertical-align: middle;
  height: 100%;
}
header .banner {
  display: inline-block;
  vertical-align: middle;
  margin: 0 auto;
  width: 85%;
  padding-bottom: 30px;
  text-align: center;
}

header .banner-text {
  width: 100%;
}
header .banner-text h1 {
  font: 90px/1.5em "opensans-bold", sans-serif;
  color: #fff;
  letter-spacing: -2px;
  margin: 0 auto 18px auto;
  text-shadow: 0px 1px 3px rgba(0, 0, 0, 0.8);
}
header .banner-text h3 {
  font: 22px/2em "librebaskerville-bold", serif;
  color: #fff;
  margin: 0 auto;
  width: 70%;
  text-shadow: 0px 1px 2px rgba(0, 0, 0, 0.5);
}
header .banner-text h3 span,
header .banner-text h3 a {
  color: #fff;
}
header .banner-text hr {
  width: 60%;
  margin: 18px auto 24px auto;
  border-color: #2f2d2e;
  border-color: rgba(150, 150, 150, 0.1);
}

/* header social links */
header .social {
  margin: 24px 0;
  padding: 0;
  font-size: 30px;
  text-shadow: 0px 1px 2px rgba(0, 0, 0, 0.8);
}
header .social li {
  display: inline-block;
  margin: 0 15px;
  padding: 0;
}
header .social li a {
  color: #fff;
}
header .social li a:hover {
  color: #11abb0;
}

/* scrolldown link */
header .scrolldown a {
  position: absolute;
  bottom: 30px;
  left: 50%;
  margin-left: -29px;
  color: #fff;
  display: block;
  height: 42px;
  width: 42px;
  font-size: 42px;
  line-height: 42px;
  color: #fff;
  border-radius: 100%;

  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
header .scrolldown a:hover {
  color: #11abb0;
}

@media only screen and (max-width: 1024px) {
  /* header styles
   ------------------------------------------------------------------ */
  header .banner-text h1 {
    letter-spacing: -1px;
    margin: 0 auto 12px auto;
  }
}

@media only screen and (max-width: 900px) {
  /* header styles
   ------------------------------------------------------------------ */
  header .banner {
    padding-bottom: 12px;
  }
  header .banner-text h1 {
    font: 78px/1.1em "opensans-bold", sans-serif;
    letter-spacing: -1px;
  }
  header .banner-text h3 {
    font: 17px/1.9em "librebaskerville-regular", serif;
    width: 80%;
  }
  header .banner-text hr {
    width: 65%;
    margin: 12px auto;
  }
  /* nav-wrap */
  #nav-wrap {
    font: 11px "opensans-bold", sans-serif;
    letter-spacing: 1.5px;
  }
}

@media only screen and (max-width: 767px) {
  /* mobile navigation
   -------------------------------------------------------------------- */
  #nav-wrap {
    font: 12px "opensans-bold", sans-serif;
    background: transparent !important;
    letter-spacing: 1.5px;
    width: auto;
    position: fixed;
    top: 0;
    right: 0;
  }
  #nav-wrap > a {
    width: 60px;
    height: 60px;
    text-align: left;
    position: relative;
    border: none;
    float: right;

    font: 0/0 a;
    text-shadow: none;
    color: transparent;
  }

  #nav-wrap > a:before,
  #nav-wrap > a:after {
    position: absolute;
    border: 2px solid #fff;
    box-shadow: 1px 1px 7px #000;
    top: 35%;
    left: 25%;
    right: 25%;
    content: "";
  }
  #nav-wrap > a:after {
    top: 60%;
  }

  /* toggle buttons */
  #nav-wrap:not(:target) > a:first-of-type,
  #nav-wrap:target > a:last-of-type {
    display: block;
  }

  /* hide menu panel */
  #nav-wrap ul#nav {
    height: auto;
    display: none;
    clear: both;
    width: auto;
    float: right;

    position: relative;
    top: 12px;
    right: 0;
  }

  /* display menu panels */
  #nav-wrap:target > ul#nav {
    display: block;
    padding: 30px 20px 48px 20px;
    background: #1f2024;
    margin: 0 30px;
    clear: both;
  }

  ul#nav li {
    display: block;
    height: auto;
    margin: 0 auto;
    padding: 0 4%;
    text-align: left;
    border-bottom: 1px solid #2d2e34;
    border-bottom-style: dotted;
  }

  ul#nav li a {
    display: block;
    margin: 0;
    padding: 0;
    margin: 12px 0;
    line-height: 16px; /* reset line-height from 48px */
    border: none;
  }

  /* Header Styles
   -------------------------------------------------------------------- */
  header .banner {
    padding-bottom: 12px;
    padding-top: 6px;
  }
  header .banner-text h1 {
    font: 68px/1.1em "opensans-bold", sans-serif;
  }
  header .banner-text h3 {
    font: 16px/1.9em "librebaskerville-regular", serif;
    width: 85%;
  }
  header .banner-text hr {
    width: 80%;
    margin: 18px auto;
  }

  /* header social links */
  header .social {
    margin: 18px 0 24px 0;
    font-size: 24px;
    line-height: 36px;
  }
  header .social li {
    margin: 0 10px;
  }

  /* scrolldown link */
  header .scrolldown {
    display: none;
  }
}

@media only screen and (max-width: 480px) {
  /* mobile navigation
   -------------------------------------------------------------------- */
  #nav-wrap ul#nav {
    width: auto;
    float: none;
  }

  /* header styles
   -------------------------------------------------------------------- */
  header .banner {
    padding-top: 24px;
  }
  header .banner-text h1 {
    font: 40px/1.1em "opensans-bold", sans-serif;
    margin: 0 auto 24px auto;
  }
  header .banner-text h3 {
    font: 14px/1.9em "librebaskerville-regular", sans-serif;
    width: 90%;
  }

  /* header social links */
  header .social {
    font-size: 20px;
  }
  header .social li {
    margin: 0 6px;
  }
}
